import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const imageConfig = {
transform(props) {
  const { ...rest } = props;
  return {
    ...rest
  };
}}

export const getImageStyle = (styles = {}) => {
  const _styles = getPatternStyles(imageConfig, styles)
  return imageConfig.transform(_styles, patternFns)
}

export const image = (styles) => css(getImageStyle(styles))
image.raw = getImageStyle