import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const paragraphConfig = {
transform(props) {
  const { textStyle, ...rest } = props;
  return {
    textStyle,
    ...rest
  };
}}

export const getParagraphStyle = (styles = {}) => {
  const _styles = getPatternStyles(paragraphConfig, styles)
  return paragraphConfig.transform(_styles, patternFns)
}

export const paragraph = (styles) => css(getParagraphStyle(styles))
paragraph.raw = getParagraphStyle